import React, {useState,useContext} from 'react';
import creditCard from '../assets/images/Credit-card.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import successImg from '../assets/images/img-ok-window.svg';
import errorImg from '../assets/images/img-error-window.svg';
import infoImg from '../assets/images/img-info-window.svg';
import i18next from 'i18next';

import {useSearchParams,useNavigate} from "react-router-dom";
 

function ModalInfo({toMarketPlace,toHistory, ammount,type,text}) {

    return (
        <div style={{position:'absolute',zIndex:'999',width:'100%', background:'rgba(255,255,255,0.5)', height:'100%'}}>
            <div class="info__window"  style={{marginTop:'40px'}}>
                
                {
                    type === 'success' ? (
                        <div>
                             <img style={{marginTop:'34px'}}   src={successImg} alt=""/>
                            <p class="info__window-title">{i18next.t('modal_info.success')}</p>
                        </div>
                       
                    ) : (
                        <div>
                             <img style={{marginTop:'34px'}}   src={infoImg} alt=""/>
                        <p class="info__window-title">{i18next.t('modal_info.attention')}</p>
                        </div>
                        
                    )
                }
                <p class="info__window-text">{text} </p>
                
                <button  onClick={() => toHistory()}  class="btn-black" style={{fontSize:'14px',marginBottom:5}}  >{i18next.t('modal_info.my_transaction')}</button>
                <button  onClick={() => toMarketPlace()}  class="btn-gradient" style={{fontSize:'14px'}}  >{i18next.t('modal_info.button')}</button>
            </div>
        </div>
    );
}

export default ModalInfo;