import React, {useState,useEffect} from 'react';
import Tinkof from '../assets/images/logo-tinkoff.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Wallet from '../assets/images/Wallet.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import Value from './../components/Value';
import ModalNetwork from '../components/ModalNetwork';
import ModalInfo from '../components/ModalInfo';
import {useSearchParams,useNavigate} from "react-router-dom";
import i18next from 'i18next';


function Crypto() {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [backLoading, setBackLoading] = useState(false);
  const [ValueSetted, setValueSetted] = useState(true);
  const [NetworkSetted, setNetworkSetted] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [WalletNuber, setWalletNuber] = useState('');
  const [Ammount, setAmmount] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [responseText, setresponseText] = useState('');
  
  const [Network , setNetworkd] = useState({'name':'Binance Smart Chain','slug':'bep20' });
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
   const [isDataOk, setIsDataOk] = useState(false);


   useEffect(() => {
    
    if (WalletNuber.length > 30) {
        setIsDataOk(true)
    }



    }, [WalletNuber]);

   
    const setValue = (value) => {
        setAmmount(value)
        sendRequest(value)
        
      }
      
  const setNetwork = (value) => {
    setNetworkSetted(true)
    setNetworkd(value)

  }

  function sendRequest(value) {
    
    const data = {
        "value": value,
        "WalletNuber": WalletNuber,
        "Network": Network,
        'method':Network?.slug
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
        body: JSON.stringify(data)
    };
    console.log(requestOptions)
    
    fetch('https://app.pubhause.com/api/wallet/new-withdraw',requestOptions)
      .then(response => {
         response.json().then(data => {
            setresponseText(data.message)
            if (response.status === 200) {
                setSuccessModal(true)
            }
            else{
                setErrorModal(true)
                
            }
            setValueSetted(true)
         })
      })
      .catch((err) => {
        console.log('Oh my error',err.message);
        setValueSetted(true)
    });

  }

  function GoBack() {
        navigate(`/?token=${token}`)
    }
   function back() {
        setValueSetted(false)
    }
    function toHistory() {
        navigate(`/history?token=${token}`)
      }
   function toMarketPlace() {
      setBackLoading(true)

      setTimeout(
         function() {
            setBackLoading(false)
         }
         .bind(this),
         3000
     );
      window.location.href  = `https://app.pubhause.com/me/?token=${token}`;
    }

    return (
        (
            ValueSetted === false  ? (<Value method={Network?.slug} setValue = {setValue}/>): (
                <div className="App">
                    { !NetworkSetted ? (<ModalNetwork setNetwork={setNetwork} />): (null)}
                    { successModal ? (<ModalInfo toHistory={toHistory}  toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'success'}/>): (null)}
                    { errorModal ? (<ModalInfo toHistory={toHistory}  toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'error'}/>): (null)}
                       <div  style={{marginTop:'40px'}} className="chose__screen"  >
                        <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:25,}}>
                                <div style={{alignSelf: 'center'}}   onClick={() => GoBack()}  > <img src={Arrow} className="App-logo" alt="logo" /></div>
                                <div className="screen__tetle" style={{alignSelf: 'center',marginBottom:0, position: 'relative', margin: 'auto', left: '-{half width of left div}px'}} >{i18next.t('crypto.nav')}</div>
                        </div>

                        <div className="block__chose">
                           
                            <p>{i18next.t('crypto.network')}</p>
                            <div className="block__chose_input"  onClick={() =>setNetworkSetted(false)}  >
                                <div className="block__chose_input-img">
                                <img   src={Net}  alt=""/>
                                </div>
                                <div className="delimiter"></div>
                                <div className="block__chose_input-btn">
                                <div className="block__chose_input-name">{Network.name}</div>
                                <img style={{transform: 'rotate(270deg)'}} src={Arrow} alt=""/>
                                </div>
                            </div>
                            <p>{i18next.t('crypto.tokens')}</p>
                            <div className="block__chose_input">
                                <div className="block__chose_input-img">
                                <img    src={Wallet}  alt=""/>
                                </div>
                                <div className="delimiter"></div>
                                <div className="block__chose_input-btn">
                                <div className="block__chose_input-name">USDT</div>
                                </div>
                            </div>

                            <p>{i18next.t('crypto.address')}</p>
                            <div className="block__chose_input">
                                <input onChange={e => setWalletNuber(e.target.value)} type="tel" id="crypto__address" maxLength="50" placeholder={i18next.t('crypto.address_placeholder')}/>
                            </div>

                        </div>
                        {/* <div className="block__chose_money">
                            <p className="block__chose_money-text">Комиссия</p>
                            <p className="block__chose_money-money">300 &#8381;</p>
                        </div>
                        <div className="block__chose_money"  style={{marginTop: '5px'}} >
                            <p className="block__chose_money-text">Вы получите</p>
                            <p className="block__chose_money-money">700 $</p>
                        </div> */}
                       {
                            isDataOk ? (
                                <button class="btn-gradient"  onClick={() => setValueSetted(false)}    style={{marginBottom: '10px'}} >
                                {i18next.t('crypto.indicate_sum')}&#8195; 
                                <img src={longArrow} alt=""/>
                            </button>
                            ) : (
                                <button className="btn-black  border-gradient-40  mt-2"  style={{marginTop:'30px',marginBottom:'10px',color: 'rgba(0,0,0,0.5)', fontSize:15}}  >
                                    {i18next.t('crypto.data')}
                                </button>

                            )
                        }
                        <button  onClick={() => toMarketPlace()}  className="btn-black"  style={{marginBottom: '26px'}} >{i18next.t('crypto.back')}</button>
                    </div>
                </div>
            )
        ) 
        
    );
}

export default Crypto;
