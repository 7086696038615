import React, {useState,useContext} from 'react';
import Sbp from '../assets/images/sbp.svg';
import i18next from 'i18next';

function CheckBox({label, value, onChange}) {

    return (
        <div  class="checkbox-wrapper-16">
        <label class="checkbox-wrapper">
            <input 
                type="checkbox" 
                class="checkbox-input" 
                value={value}
                onChange={onChange}
                
                />
            <span class="checkbox-tile">
            <span class="checkbox-icon">
                <img src={Sbp}/>
            </span>
            <span class="checkbox-label">{label}</span>
            </span>
        </label>
        </div>
    );
}

export default CheckBox;


