import React, {useState,useContext,useEffect} from 'react';
import creditCard from '../assets/images/Credit-card.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import Wallet from '../assets/images/Wallet.svg';
import {useSearchParams,useNavigate} from "react-router-dom";
import {UserContext}  from './../App';
import i18next from 'i18next';
 


function WithdrawValue({method,setValue}) {
  const [cryptoW, setCryptoW] = useState(false);
  const [userCommisson, setCommission ] = useState({});
  const navigate = useNavigate();
  const [backLoading, setBackLoading] = useState(false);
  const [withdrawAvailable, setWithdrawAvailable] = useState(false);
  const [withdrawValue, setWithdrawValue] = useState(0);
  const [minValue, setMinValue] = useState(1);
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { user, setUser } = useContext(UserContext);



//   useEffect(() => {
//     const requestOptions = {
//        method: 'GET',
//        headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
//    };
//     fetch('https://app.pubhause.com/api/wallet/get-commission',requestOptions)
//     .then((response) => response.json())
//     .then(data => {
//         setCommission(data);
//     });

//  }, []);


 useEffect(() => {
    if (method == 'card') {
        setMinValue(1.3)
    }
    else if (method == 'sbp') {
        setMinValue(1.3)
    }
    else if (method == 'eth') {
        setMinValue(5)
    }
    else if (method == 'trc20') {
        setMinValue(1.2)
    }
    else {
        setMinValue(0.2)
    }

 }, [method]);



 function newRequest() {
    setBackLoading(true);
    const ResultWithdraw = commission(withdrawValue,true);
    console.log('newRequest',ResultWithdraw)
    setValue(ResultWithdraw);
 }

 function commission(value, total = false) {

        const total_withdraw = 0 - user?.wallet?.balance;
        var k = 1
        if (total_withdraw<20) {
            k = 0.1
        }
        const com_result = {
            'trc20': 1.2*k,
            'bep20': 0.13*k,
            'eth': 9,
            'sbp': k*(0.05*value+1/8),
            'card': k*(0.05*value+1/8),
        }[method]
        if (total) return Math.round(com_result*100)/100 + value*1
        else  return Math.round(com_result*100)/100
    }

   function back() {
      if (cryptoW) navigate(`/?token=${token}`);
      else navigate(`/?token=${token}`);
   }

   function changeValue(value) {
        let balance = user?.wallet?.balance

        if (method == 'card') {
            balance = user?.wallet?.balance_rub
        }

        if ( value <= minValue) {
            setWithdrawAvailable(false)
        }
        else if (balance <= commission(value,true) ) {
            setWithdrawAvailable(false)
        }
        else{
            setWithdrawAvailable(true)
        }

        setWithdrawValue(value)
    }



    return (
      <div className="chose__screen" style={{paddingTop:'70px'}}>
    

        <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:25,}}>
                <div style={{alignSelf: 'center',padding:20}}   onClick={() => back()}  > <img src={Arrow} className="App-logo" alt="logo" /></div>
                <div className="screen__tetle" style={{alignSelf: 'center',marginBottom:0, position: 'relative', margin: 'auto', left: '-{half width of left div}px'}} >{i18next.t('value.title')} </div>
        </div>
        


        <div className="block__chose">
            <p>{i18next.t('value.sum')} </p>
            <div className="block__chose_input">
                <input
                    type="number"
                    id="amount__money"
                    onChange={e => changeValue(e.target.value) }
                    maxLength="36"
                    placeholder={i18next.t('value.title_$')} />
            </div>
        </div>
        
        
        <div className="block__chose_money">
            <p className="block__chose_money-text">{i18next.t('value.available')}</p>
            {
                method == 'card' ? (
                    <p className="block__chose_money-money">{user?.wallet?.balance_rub} руб.</p>
                ) : (
                    <p className="block__chose_money-money">{user?.wallet?.balance} $</p>
                )
            }
        </div>
        
        <div className="block__chose_money"  >
            <p className="block__chose_money-text">{i18next.t('value.write_off')}</p>
            <p className="block__chose_money-money">{commission(withdrawValue,true)} 
            { method == 'card' ? ' руб.' : ' $'  }
            </p>
        </div>
        <div className="block__chose_money">
            <p className="block__chose_money-text">{i18next.t('value.comission')}</p>
            <p className="block__chose_money-money">{commission(withdrawValue)} 
                { method == 'card' ? ' руб.' : ' $'  }
            </p>
        </div>


        {
            withdrawAvailable ? (
                backLoading ? (
                    <button className="btn-black  border-gradient-40  mt-2"  style={{marginTop:'30px',marginBottom:'10px',color: 'rgba(0,0,0,0.5)', fontSize:15}}  >
                        {i18next.t('value.processing')}
                    </button>
                    
                ):(
                    <button onClick={() => {
                        newRequest()
                    }
                    } className="btn-gradient mt-2"  style={{marginTop:'30px',marginBottom:'10px'}}  >
                        {i18next.t('value.withdraw')}&#8195;
                        <img src={longArrow} alt=""/>
                    </button>
                ) 
                

            ) : (
                <button className="btn-black  border-gradient-40  mt-2"  style={{marginTop:'30px',marginBottom:'10px',color: 'rgba(0,0,0,0.5)', fontSize:15}}  >
                    {i18next.t('value.data')}
                </button>
            )
        }

        <div className='block__chose' >
            <p  style={{marginTop:'10px',marginBottom:'10px',color: 'rgba(0,0,0,0.5)', fontSize:13}} >
                {i18next.t('value.choice')} <strong style={{textTransform:'uppercase'}}>  {method} </strong> {i18next.t('value.method')}. {i18next.t('value.desc')} .
            </p>
        </div>
    </div>
    );
}

export default WithdrawValue;
