import React, {useState,useContext,useEffect} from 'react';
import creditCard from '../assets/images/Credit-card.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import Wallet from '../assets/images/Wallet.svg';
import {useSearchParams,useNavigate} from "react-router-dom";
import {UserContext}  from '../App';
import i18next from 'i18next';
 

function Crypto({setBank}) {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [backLoading, setBackLoading] = useState(false);
  const [withdrawValue, setWithdrawValue] = useState('');
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
  const { user, setUser } = useContext(UserContext);
  const [banks, setBanks] = useState([
        {
            "id": 2,
            "name": "Тинькофф",
            "logo": "https://app.pubhause.com/media/bank_logos/logo-tinkoff-bank.png"
        },
        {
            "id": 1,
            "name": "Сбербанк",
            "logo": "https://app.pubhause.com/media/bank_logos/logo-sber.png"
        },
        
        // {
        //     "id": 3,
        //     "name": "Росбанк",
        //     "logo": "https://app.pubhause.com/media/bank_logos/logo-rosbank.png"
        // },
        // {
        //     "id": 4,
        //     "name": "Райфайзен",
        //     "logo": "https://app.pubhause.com/media/bank_logos/logo-raiffeisen-bank.jpg"
        // },
        // {
        //     "id": 6,
        //     "name": "Газпромбанк",
        //     "logo": "https://app.pubhause.com/media/bank_logos/logo-gazprombank_zJfWUIp.jpg"
        // },
        // {
        //     "id": 7,
        //     "name": "Qiwi",
        //     "logo": "https://app.pubhause.com/media/bank_logos/logo-qiwi.png"
        // },
        // {
        //     "id": 8,
        //     "name": "Альфа-банк",
        //     "logo": "https://app.pubhause.com/media/bank_logos/logo-alfa-bank.jpg"
        // }
    ]);
    function back() {
        navigate(`/?token=${token}`);
     }

  useEffect(() => {
    const requestOptions = {
       method: 'GET',
       headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
   };
    fetch('https://app.pubhause.com/api/p2p/payment-methods',requestOptions)
    .then((response) => response.json())
    .then(data => {
        console.log(data)
       setBanks(data?.data);
    });

 }, []);



    return (
        <div style={{position:'absolute',zIndex:'999',width:'100%', background:'rgba(255,255,255,0.5)', height:'100%'}}>
            <div className="chose__connection" style={{marginTop:'35px'}}>

            <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:25,}}>
                    <div style={{alignSelf: 'center',padding:20}}   onClick={() => back()}  > <img src={Arrow} className="App-logo" alt="logo" /></div>
                    <div className="screen__tetle" style={{alignSelf: 'center',marginBottom:0, position: 'relative', margin: 'auto', left: '-{half width of left div}px'}} >{i18next.t('modal_bank.title')} </div>
            </div>
            
            {
                banks.map((bank) => {
                    return (
                        <div className="block__chose_item border-gradient-40"  onClick={() => setBank(bank)} >
                            <div>{bank.name}</div>
                            {
                                bank.logo   ? (
                                    <img height="18" src={bank.logo} alt=""/>
                                ) : (null)
                            }
                            
                        </div>
                    )
                })
            }
           
            <button onClick={() => setBank(banks[0])}  className="btn-black" style={{marginTop: '16px'}}>{i18next.t('modal_bank.button')}</button>
        </div>
     </div>
    );
}

export default Crypto;
