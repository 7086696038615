import React, {useState,useContext,useEffect} from 'react';
import Tinkof from '../assets/images/logo-tinkoff.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Telephone from '../assets/images/telephone.svg';
import Arrow from '../assets/images/arrow.svg';
import FlagRu from '../assets/images/flag-rus.svg';
import Value from './../components/Value';
import ModalBank from '../components/ModalBank';
import {useSearchParams,useNavigate} from "react-router-dom";
import ModalInfo from '../components/ModalInfo';
import CheckBox from '../components/checkBox';
import {UserContext}  from './../App';
import i18next from 'i18next';


function Fiat() {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [backLoading, setBackLoading] = useState(false);
  const [isDataOk, setIsDataOk] = useState(false);
  
  const [ValueSetted, setValueSetted] = useState(true);
  const { user, setUser } = useContext(UserContext);
  const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
   const [successModal, setSuccessModal] = useState(false);
   const [errorModal, setErrorModal] = useState(false);

   const [Ammount, setAmmount] = useState('');
   const [responseText, setresponseText] = useState('');

  const [bankSetted, setBankSetted] = useState(false);
  const [bankValue , setBankValue] = useState({'name':'Тинькоф Банк','slug':'tinkof' });
  const [phone , sePhone] = useState('');
  const [card , setCard] = useState('');
  const [fio , setFio] = useState('');
  const [sbp, setSbp] = useState(false);



  const setValue = (value) => {
    setAmmount(value)
    sendRequest(value)
    
  }
  useEffect(() => {
    if (phone.length < 10) {
        setIsDataOk(false)
    }

    else if (sbp && fio.length < 6) {
        setIsDataOk(false)
    }
    else if (!sbp && card.length < 19) {
        setIsDataOk(false)
    }
    else{
        setIsDataOk(true)
    }



    }, [sbp,fio,phone,card]);

  function sendRequest(value) {
    

    const data = {
        "value": value,
        "bank": bankValue,
        "spb": sbp,
        "fio": fio,
        "phone": phone,
        "card": card,
        'method':sbp ? 'sbp' : 'card'
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
        body: JSON.stringify(data)
    };


    fetch('https://app.pubhause.com/api/wallet/new-withdraw',requestOptions)
      .then(response => {
         response.json().then(data => {
            setresponseText(data.message)
            if (response.status === 200) {
                setSuccessModal(true)
            }
            else{
                setErrorModal(true)
            }

            setValueSetted(true)
        })
      })
      .catch((err) => {
        console.log(err.message);
        setValueSetted(true)
    });

  }

  const setBank = (value) => {
    setBankSetted(true)
    setBankValue(value)
  }
  const changeName = (value) => {
    setFio(value)
  }


  function cc_format(value) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
  }

  function getFormattedPhoneNum( input ) {
    input = input.replace( "+7 ", "" );
    let output = "+7 (";
    input.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
        {
          if ( g1.length ) {
            output += g1;
            if ( g1.length === 3 ) {
                output += ")";
                if ( g2.length ) {
                    output += " " + g2;
                    if ( g2.length === 3 ) {
                        output += " - ";
                        if ( g3.length ) {
                            output += g3;
                        }
                    }
                }
             }
          }
        }
      );
    return output;
   }

   const handleChangeSbp = () => {
    setSbp(!sbp);
  };

   function onPhoneChange(phone) {
    let newPhone = getFormattedPhoneNum(phone)
    console.log(newPhone)
    sePhone(newPhone)
  }
  function onCardChange(value) {
    let newCard = cc_format(value)
    console.log(value)
    setCard(newCard)
  }

  function GoBack() {
    navigate(`/?token=${token}`)
 }

  function toHistory() {
    navigate(`/history?token=${token}`)
  }

   function toMarketPlace() {
      setBackLoading(true)

      setTimeout(
         function() {
            setBackLoading(false)
         }
         .bind(this),
         3000
     );
      window.location.href  = `https://app.pubhause.com/me/?token=${token}`;
    }

    return (
        (
            ValueSetted===false ? (<Value method={sbp ? 'sbp' : 'card'} setValue = {setValue}/>): (
                <div className="App" >
                    { !bankSetted ? (<ModalBank setBank={setBank} />): (null)}
                    { successModal ? (<ModalInfo toHistory={toHistory} toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'success'}/>): (null)}
                    { errorModal ? (<ModalInfo toHistory={toHistory} toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'error'}/>): (null)}
                    <div style={{marginTop:'40px'}}  class="chose__screen">
                       
                        <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:25,}}>
                                <div style={{alignSelf: 'center'}}   onClick={() => GoBack()}  > <img src={Arrow} className="App-logo" alt="logo" /></div>
                                <div className="screen__tetle" style={{alignSelf: 'center',marginBottom:0, position: 'relative', margin: 'auto', left: '-{half width of left div}px'}} >{i18next.t('fiat.nav')}</div>
                        </div>


                        <div   class="block__chose">
                            <div style={{marginBottom:10,color:'#fff',fontSize:10, marginLeft:0}} className="span-gradient">{i18next.t('fiat.available_balance')}: <span>{user?.wallet?.balance_rub} руб.</span></div>
                            <p style={{marginBottom:10}}>{i18next.t('fiat.choose_bank')}</p>

                            <div  style={{display:'flex'}}  >
                                <div className="" style={{flex: '0 0 70%', marginRight:10, marginTop:0}}>
                                <div class="block__chose_input bank-choose "  style={{height:97,marginTop:0}} onClick={() =>setBankSetted(false)} >
                                    <div class="block__chose_input-img">
                                        {
                                            bankValue.logo   ? (
                                                <img width="40" src={bankValue.logo} alt=""/>
                                            ) : (null)
                                        }

                                    </div>
                                    <div class="delimiter"></div>
                                    <div class="block__chose_input-btn">
                                        <div class="block__chose_input-name">{bankValue.name}</div>
                                        <img style={{transform: 'rotate(270deg)'}} src={Arrow} alt=""/>
                                    </div>
                                </div>

                            </div>


                            <CheckBox
                                label="СБП"
                                value={sbp}
                                onChange={handleChangeSbp}
                                style={{flex: '0 0 30%'}}
                                />

                        </div>




                            <p>{i18next.t('fiat.input_phone')} </p>
                            <div class="block__chose_input">
                                <div class="block__chose_input-img">
                                <img src={Telephone} alt=""/>
                                </div>
                                <div class="delimiter"></div>

                                <input id="phone__number" type="tel" maxLength="50"
                                    autofocus="autofocus" value={phone}
                                    placeholder="+7 (915) 123 0000"
                                 onChange={e => onPhoneChange(e.target.value)} />
                            </div>

                            {
                                 sbp ? (
                                    <div>
                                        <p>{i18next.t('fiat.input_name')} </p>
                                        <div class="block__chose_input">
                                            <input  id="phone__number" type="text" id="fio" maxLength="19"
                                                onChange={e => changeName(e.target.value)}
                                                placeholder="50 CENT" value={fio} />
                                        </div>
                                    </div>
                                 ): (
                                    <div>
                                        <p>{i18next.t('fiat.input_card')} </p>
                                        <div class="block__chose_input">
                                            <input type="tel" id="card__number" maxLength="19"
                                                onChange={e => onCardChange(e.target.value)}
                                                placeholder="XXXX XXXX XXXX XXXX" value={card} />
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                        {/* <p class="screen__text" style={{margin: '4px 0 40px'}} >«Вывод денежных средств осуществляется в течении 24 часов,
                            пожалуйста будьте внимательны
                            при вводе данных карты»
                        </p> */}
                        {
                            isDataOk ? (
                                <button class="btn-gradient"  onClick={() => setValueSetted(false)}    style={{marginBottom: '10px'}} >
                                {i18next.t('fiat.indicate_sum')}&#8195; 
                                <img src={longArrow} alt=""/>
                            </button>
                            
                            ) : (
                                <button className="btn-black  border-gradient-40  mt-2"  style={{marginTop:'30px',marginBottom:'10px',color: 'rgba(0,0,0,0.5)', fontSize:15}}  >
                                    {i18next.t('fiat.data')}
                                </button>
                                
                            )
                        }
                        
                        <button class="btn-black" onClick={() => toMarketPlace()}  style={{marginBottom: '26px'}} >{i18next.t('fiat.back')}</button>
                    </div>
                </div>
            )
        )

    );
}

export default Fiat;
