import React, {useState,useEffect,useContext} from 'react';
import operationCompleted from '../assets/images/operation-completed.svg';
import Arrow from '../assets/images/arrow.svg';
import btnClose from '../assets/images/btn-close.svg';
import imgLoadWhite from '../assets/images/img-load-white.svg';
import imgUpdateWindow from '../assets/images/img-update-window.svg';
import operationCanceled from '../assets/images/operation-canceled.svg';
import operationInProgres from '../assets/images/operation-in-progress.svg';


import {useSearchParams,useNavigate} from "react-router-dom";
import {UserContext}  from '../App';
import i18next from 'i18next';

function Main() {
  const [detailInfo, setDetailInfo] = useState(false);
  const navigate = useNavigate();
  const [withdrawValue , setWithdrawValue] = useState([]);
  const [ItemValue , setItemValue] = useState({});
  const [backLoading, setBackLoading] = useState(true);
   const [searchParams] = useSearchParams();
   const token = searchParams.get('token');
   useEffect(() => {
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
     };
      fetch('https://app.pubhause.com/api/wallet/withdraw-history',requestOptions)
      .then((response) => response.json())
      .then(data => {
         setWithdrawValue(data?.data);
         // 
         setBackLoading(false)
      });

   }, []);
   
   
   
   function GoBack() {
      navigate(`/?token=${token}`)
   }

   function getDetail(item) {
      setItemValue(item)
      setDetailInfo(true)
    }
    function closeDetailInfo() {
      setDetailInfo(false)
    }

    


    return (
      <div className="App" style={{paddingTop:'40px'}}>
         <div class="transaction__screen" >
      <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:25,}}>
            <div style={{alignSelf: 'center',padding:20}}   onClick={() => GoBack()}  > <img src={Arrow} className="App-logo" alt="logo" /></div>
            <div className="screen__tetle" style={{alignSelf: 'center',marginBottom:0, position: 'relative', margin: 'auto', left: '-{half width of left div}px'}} >{i18next.t('history.withdrawal_history')}</div>
      </div>

      <div class="swiper mySwiper2">
         <div class="swiper-wrapper">
            <div class="swiper-slide">
               <div class="transaction__screen_tabl">
                  {backLoading ? (
                     <div>

                        <div className='loader'>{i18next.t('history.loading')}</div>
                        <div style={{textAlign: 'center', width:'60%',margin:'auto'}}>                
                           <span className='gradient-text'>Public House</span> {i18next.t('history.text')}
                        </div>
                     </div>
                  ): (null)}

                  {withdrawValue.length===0 && backLoading===false ? (
                        <div style={{textAlign: 'center', width:'50%',margin:'auto',paddingTop:10}}>                
                           <span className='gradient-text'>{i18next.t('history.no_withdraw')}</span> 
                           <br/>
                           <img style={{marginTop:20}} src={operationCanceled} alt=""/>
                        </div>
                  ): (null)}


                  { 
                     withdrawValue.map((withdraw) => {
                           return (
                              // withdraw.withdrawed ? (
                                 <div class="transaction__screen_tabl-row" 
                                    onClick={() => getDetail(withdraw)} 
                                    >
                                    <div class="transaction__screen_tabl-img">
                                       {withdraw.withdrawed ? (
                                          <img src={operationCompleted} alt=""/>
                                          ):(
                                             <img src={operationInProgres} alt=""/>
                                          )}
                                       
                                    </div>
                                    <div class="transaction__screen_tabl-operation">
                                       <div class="transaction__screen_tabl-operation-what">
                                          
                                          {withdraw.withdrawed ? (
                                          i18next.t('history.complite_withdraw')
                                          ):(
                                             i18next.t('history.wait_withdraw')
                                          )}
                                          <img src={Arrow} alt=""/>
                                       </div>
                                       
                                       <div class="transaction__screen_tabl-operation-where">
                                          
                                          {withdraw.type === "fiat" ? (i18next.t('history.bank_transfer')) : ( 
                                             withdraw.type === "crypto" ? (i18next.t('history.crypto_address')) : (
                                                withdraw.type === "inner" ? (i18next.t('history.domestic')) : (
                                                   i18next.t('history.other_method')
                                                   )
                                                )
                                          )}

                                       </div>
                                    </div>
                                    <div class="transaction__screen_tabl-data">
                                          {withdraw.withdrawed ? (
                                          <div class="transaction__screen_tabl-data-sum green">{withdraw.value} $</div>
                                          ):(
                                             <div class="transaction__screen_tabl-data-sum grey">{withdraw.value} $</div>
                                          )}

                                       
                                       <div class="transaction__screen_tabl-data-date">{withdraw.created}</div>
                                    </div>
                                 </div> 
                           //    ): (
                           //       <div class="transaction__screen_tabl-row">
                           //          <div class="transaction__screen_tabl-img">
                           //             <img src={operationInProgres} alt=""/>
                           //          </div>
                           //          <div class="transaction__screen_tabl-operation">
                           //             <div class="transaction__screen_tabl-operation-what">Ожидание вывода
                           //                <img src={Arrow} alt=""/>
                           //             </div>
                           //             <div class="transaction__screen_tabl-operation-where">Карта</div>
                           //          </div>
                           //          <div class="transaction__screen_tabl-data">
                           //             <div class="transaction__screen_tabl-data-sum grey">200 $</div>
                           //             <div class="transaction__screen_tabl-data-date">02-10-22</div>
                           //          </div>
                           //       </div> 
                           //    )
                               
                           )
                        })
                  }
                  
                  
                  
                  



                  {/* <div class="transaction__screen_tabl-row">
                     <div class="transaction__screen_tabl-img">
                        <img src={operationCanceled} alt=""/>
                     </div>
                     <div class="transaction__screen_tabl-operation">
                        <div class="transaction__screen_tabl-operation-what">Вывод отклонен
                           <img src={Arrow} alt=""/>
                        </div>
                        <div class="transaction__screen_tabl-operation-where">Крипто-кошелек</div>
                     </div>
                     <div class="transaction__screen_tabl-data">
                        <div class="transaction__screen_tabl-data-sum grey">200 $</div>
                        <div class="transaction__screen_tabl-data-date">02-10-22</div>
                     </div>
                  </div>  */}
               </div>
            </div>
         </div>
      </div>
   </div>

      {
         detailInfo ? (
            <div
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  top:0,
                  width:'100%',
                  height:'100%',
                  opacity: 0.96,
                  position: 'absolute',
                  
                  }}
                  class='whiteBlur'
            >
               <div class="transaction-data__window" 
                  
               >
                  <div class="btn-close"  onClick={closeDetailInfo} >
                     <img src={btnClose}  alt=""/>
                  </div>
                  <div class="transaction-data__operation-name">{i18next.t('history.withdrawal')}</div>
                  <div class="transaction-data__row">
                     <div id="transaction_data_name">{i18next.t('history.status')}</div>
                     {ItemValue.withdrawed ? (
                     <div id="transaction_data_result" style={{color: '#3AAF5C'}}>{i18next.t('history.approved')}</div>
                     ):(
                        <div id="transaction_data_result" style={{color: 'gray'}}>{i18next.t('history.consideration')}</div>

                     )}
                     
                     
                  </div>
                  {/* {JSON.stringify(ItemValue)} */}
                  <div class="transaction-data__operation-tabl">
                     <div class="transaction-data__row">
                        <div>{i18next.t('history.sum')}</div>
                        <div id="transaction_data_price">{ItemValue.value} $</div>
                     </div>

                     {ItemValue.type === "fiat" ? (
                         <div class="transaction-data__row">
                           <div>{i18next.t('history.number_card')}</div>
                           <div id="transaction_data_card_number">{ItemValue.address}</div>
                        </div>
                     ) : ( 
                        ItemValue.type === "crypto" ? (
                              <div class="transaction-data__row">
                                 <div>{i18next.t('history.crypto_address')}</div>
                                 <div id="transaction_data_card_number">{ItemValue.address}</div>
                              </div>
                              
                           ) : (
                              ItemValue.type === "inner" ? (
                                 
                              <div class="transaction-data__row">
                                 <div>{i18next.t('history.domestic')}</div>
                                 <div id="transaction_data_card_number">{ItemValue.address}</div>
                              </div>

                              ) : (
                                 <div class="transaction-data__row">
                                    <div>{i18next.t('history.other_method')}</div>
                                    <div id="transaction_data_card_number">{JSON.stringify(ItemValue.meta.comment)}</div>
                                 </div>
                                 )
                              

                           )
                        )}
                        
                    
                     <div class="transaction-data__row">
                        <div>{i18next.t('history.date')}</div>
                        <div id="transaction_data_time">{ItemValue.created}</div>
                     </div>
                  </div>
               </div>
            </div>
            
         ) : (null)
      }
      

    </div>
    );
}

export default Main;
